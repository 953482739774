import { useState } from 'react';
// Ui
import Modal from 'ui3/Modal/Modal';
// Components
import VideoControlButton from 'ui/VideoPlayer/components/VideoControlButton/VideoControlButton';

import styles from './VideoSettings.module.scss';

type VideoSettingsProps = {
  settings: JSX.Element;
};

const VideoSettings = ({ settings }: VideoSettingsProps) => {
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);

  const handleModalToggle = () => {
    setModalVisibility((prev) => !prev);
  };

  return (
    <>
      <div className={styles.root}>
        <VideoControlButton
          icon="gear"
          title="Change video/audio recording devices"
          onClick={handleModalToggle}
        />
      </div>

      <Modal
        title="Settings"
        size="small"
        open={isModalVisible}
        onClose={handleModalToggle}
        focusTrapped={false}
      >
        {settings}
      </Modal>
    </>
  );
};

export default VideoSettings;
